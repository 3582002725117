@import "../../../../variables";

.PlanDatePoll {

  &__header {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &__container {
    margin-bottom: 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 8px;

    &__widget {
      width: 80px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white;
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0px 2px 4px rgba(5, 4, 66, 0.1);
      border-radius: 12px;
      overflow: hidden;
      box-sizing: border-box;
      gap: 0px;

      &--selectable {
        cursor: pointer;

        &:hover {
          border: 1px solid $bright-pink-tenth-opacity;
          box-shadow: 0px 2px 4px $bright-pink-fifth-opacity;
        }
      }

      &__header {
        height: 28px;
        background: $blueberry-550;
        align-self: stretch;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &__month {
          color: $white;
        }
      }

      &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0px;
        padding-top: 5px;

        &__day-of-month {
          font-size: 20px;
          font-weight: 600;
        }

        &__day-of-week {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    &__votes {
      margin: 8px 0 0 0;
      height: 24px;
      width: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $gray-30;
      border-radius: 48px;
      box-sizing: border-box;

      &--selected {
        background: $blueberry-550;
        color: $white;
      }

      &:hover {
        cursor: pointer;
        border: 1px solid $bright-pink-tenth-opacity;
        box-shadow: 0px 2px 4px $bright-pink-fifth-opacity;
      }

      &__text {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}
