@import "../../../../variables";

.ActivePollOption {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border: 2px solid $blueberry-50;
  border-radius: 12px;
  align-items: flex-start;
  cursor: pointer;
  padding: 0.75rem 0.5rem;
  margin-bottom: 8px;
  color: $button-secondary-text-color;
  font-weight: 600;
  font-size: 16px;

  &--selected {
    background-color: $blueberry-50;
    border: 2px solid $blueberry-50;
  }

  @media (min-width: $mobile-width-cutoff) {
    &:hover {
      background-color: $button-secondary-bg-color-hover;
      border: 2px solid $button-secondary-bg-color-hover;

      .ActivePollOption__number {
        background-color: $white;
      }
    }
  }

  &__flex {
    display: flex;
    align-items: flex-start;

    &__circle{
      height: 24px;
      width: 24px;
      margin-right: 4px;
    }
  }


  &__number {
    color: $blueberry-550;
    background-color: $blueberry-50;
    height: 24px;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 2px;

    &--selected {
      background-color: $white;
    }
  }

  &__number-flex {
    display: flex;
    flex-direction: row;

    &__number-votes {
      padding-left: 3px;
    }
  }
  
}
