@import "../../../../variables";


.Poll {
  margin-top: 12px;
  margin-bottom: 24px;

  &__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;

    &__user-picture {
      object-fit: cover;
      height: 2.25rem;
      width: 2.25rem;
      border-radius: 50%;
      box-sizing: border-box;
      border: 2px solid $white;
    }

    &__text {
      margin-left: 12px;
    }
  }

  

  &__options {
    list-style: none;
    margin: auto;
    padding-inline-start: 48px;
  }
}


