@import "../../../../variables";

.ResultsPollOption {
  margin-top: 4px;
  margin-bottom: 16px;
  min-height: 33px;

  &__votes{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 600;
  }

  &__votes-number {
    background-color: $blueberry-50;
    height: 24px;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: $blueberry-550;

    &--winner {
      background-color: $bright-pink;
      color: $white;
    }
  }

  &__progress-container {
    height: 5px;
    width: 100%;
    background-color: transparent;
    margin-top: 4px;
  }

  &__progress-bar {
    background-color: $blueberry-50;
    width: 20px;
    height: 100%;

    &--winner {
      background-color: $bright-pink;
    }
  }

  &__votes-number-flex {
    display: flex;
    align-items: center;
    align-items: flex-start;
  }

  &__votes-number-text {
    padding-left: 3px;
  }
}
